(function($) {
    
    var winheight = $(window).height();
    $('.imgouter').height(winheight);
    $(window).resize(function() {
        var winheight = $(window).height();
        $('.imgouter').height(winheight);
    })

    $('#slider').slick({
        autoplay: true,
        rows: 0,
        autoplaySpeed: 7000,
        prevArrow: '<div class="slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i> Prev</div>',
        nextArrow: '<div class="slick-next">Next <i class="fa fa-angle-right" aria-hidden="true"></i></div>',
    });
    $('#TestimonialSlider').slick({
        dots: false,
        rows: 0,
        autoplay: true,
        autoplaySpeed: 7000,
        prevArrow: '<div class="slick-prev"><i class="fa fa-chevron-left" aria-hidden="true"></i></div>',
        nextArrow: '<div class="slick-next"><i class="fa fa-chevron-right" aria-hidden="true"></i></div>',
        responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],

    });
    jQuery('.galleryslider').slick({
        dots: false,
        rows: 0,
        autoplay: true,
        centerMode: true,
        centerPadding: '120px',
        slidesToShow: 3,
        autoplaySpeed: 7000,
        prevArrow: '<div class="slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></div>',
        nextArrow: '<div class="slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i></div>',
        responsive: [{
                breakpoint: 1024,
                settings: {
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    centerMode: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '80px',
                },
            },
            {
                breakpoint: 480,
                settings: {
                    centerMode: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '40px',
                },
            },
        ],

    });
    jQuery('.footerslider').slick({
        dots: false,
        autoplay: true,
        slidesToShow: 10,
        speed: 5000,
        cssEase: 'linear',
        infinite: true,
        prevArrow: false,
        nextArrow: false,
        rows: 0,
        focusOnSelect: true,
        pauseOnHover: true,
        responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 7,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
        ],

    });
    jQuery('.teamslider').slick({
        dots: false,
        autoplay: true,
        rows: 0,
        autoplaySpeed: 7000,
        slidesToShow: 4,
        prevArrow: '<div class="slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></div>',
        nextArrow: '<div class="slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i></div>',
        responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],

    });

    $('.destination li').click(function() {
        $('.destination li').removeClass('active');
        $(this).addClass('active');
        $('.loader').fadeIn();
        $('.galleryslider').fadeOut();
        var id;
        if ($(this).attr('id')) {
            id = $(this).attr('id');
        } else {
            id = 'all';
        }
        $.ajax({

            type: 'post',
            url: '/wp-admin/admin-ajax.php',
            data: {
                'action': 'gallerySlider',
                'id': id,
            },
            success: function(message) {
                $('.loader').fadeOut();
                $('#gallery_Slider').html(message);
            },
        });
    });

    $('.slider-for').slick({
        
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        rows: 0,
        prevArrow: false,
        nextArrow: false,
        asNavFor: '.slider-nav',
    });
    $('.slider-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: false,
        rows: 0,
        arrows: true,
        centerMode: true,
        prevArrow: false,
        nextArrow: false,
        focusOnSelect: true,
    });

    $('.scrolltotop').click(function() {
        $(document).scrollTop(0);
    });

    $('a[href*=#]:not([href=#])').click(function() {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {

            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top - 60,
                }, 1000);
                return false;
            }
        }
    });
    $(document).scroll(function() {

        if ($(document).scrollTop() > 1) {
            $('body').addClass('fixednav');
            $('.topHeader').addClass('bg-white');
            
        } else {
            $('body').removeClass('fixednav');
            $('.topHeader').removeClass('bg-white');
        }
        
    })
    $('.navbar-toggler').click(function() {
        $('html').toggleClass('noscroll');
    });
})(jQuery)